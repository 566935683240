<template>      
  <div class="home">    
    <HelloWorld msg="Välkommen"/>   
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  methods:{
    logout(){      
        localStorage.setItem('aut', false)
        this.$router.push('login')       
    },
    viewMaps(){
      this.$router.push('map')
    }
  },
}
</script>
