import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },  
  {
    path: '/start',
    name: 'start',
    component: () => import(/* webpackChunkName: "start" */ '../views/StartView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "map" */ '../views/MapView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/danmark',
    name: 'danmark',
    component: () => import(/* webpackChunkName: "danmark" */ '../views/DanmarkView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/danmarkdit',
    name: 'danmarkdit',
    component: () => import(/* webpackChunkName: "danmarkdit" */ '../views/DanmarkditView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/danmarkone',
    name: 'danmarkone',
    component: () => import(/* webpackChunkName: "danmarkone" */ '../views/DanmarkoneView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/danmarktwo',
    name: 'danmarktwo',
    component: () => import(/* webpackChunkName: "danmarktwo" */ '../views/DanmarktwoView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/danmarkhem',
    name: 'danmarkhem',
    component: () => import(/* webpackChunkName: "danmarkhem" */ '../views/DanmarkhemView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tidaholm',
    name: 'tidaholm',
    component: () => import(/* webpackChunkName: "tidaholm" */ '../views/TidaholmView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tidaholmdit',
    name: 'tidaholmdit',
    component: () => import(/* webpackChunkName: "tidaholmdit" */ '../views/TidaholmditView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tidaholmone',
    name: 'tidaholmone',
    component: () => import(/* webpackChunkName: "tidaholmone" */ '../views/TidaholmoneView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tidaholmtwo',
    name: 'tidaholmtwo',
    component: () => import(/* webpackChunkName: "tidaholmtwo" */ '../views/TidaholmtwoView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tidaholmhem',
    name: 'tidaholmhem',
    component: () => import(/* webpackChunkName: "tidaholmhem" */ '../views/TidaholmhemView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/danmarkny',
    name: 'danmarkny',
    component: () => import(/* webpackChunkName: "danmarkny" */ '../views/DanmarknyView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/uploadgpx',
    name: 'uploadgpx',
    component: () => import(/* webpackChunkName: "uploadgpx" */ '../views/GpxuploadView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/gpx',
    name: 'gpx',
    component: () => import(/* webpackChunkName: "gpx" */ '../views/downloadFile.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];



const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (localStorage.getItem('aut') === 'true') {
      next() // go to wherever I'm going
    } else {
      next({ name: 'login' })      
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
});


export default router
